import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import * as React from 'react';
import Infinite from 'react-infinite';
import {connect} from 'react-redux';
import {NavLink} from 'react-router-dom';
import Slider from 'react-slick';
import TextTruncate from 'react-text-truncate';

import { ProductImage } from '@partssourceinc/react-ui-core';
import PageMetaData from 'components/PageMetaData';
import * as CartStore from 'stores/Cart';
import * as NetworkStore from 'stores/Network';
import {deleteCookie, getCookie, setCookie, logEvent} from 'utility';

import 'less/browsinghistory.less';
import 'less/carousel/carousel.less';
import 'less/carousel/slick-theme.less';

const carouselSettings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
};

const momentCalendarSettings = {
    lastDay: '[Yesterday]',
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    lastWeek: '[Last] dddd',
    nextWeek: '[Next] dddd',
    sameElse: 'L',
};

@connect(
    state => ({network: state.network, cart: state.cart}),
    _.merge({}, NetworkStore.actionCreators, CartStore.actionCreators)
)
export default class BrowsingHistory extends React.Component {
    static propTypes = {
        ...NetworkStore.ActionShape,
        ...CartStore.ActionShape,
        network: NetworkStore.StateShape,
        cart: CartStore.StateShape,
    };

    constructor(props) {
        super(props);
        this.state = {
            viewItems: [],
            history: {},
            isBusy: false,
            viewIsEmpty: false,
            moreAvailable: true,
            start: 0,
            limit: 50,
        };
        this.loadMoreHistory = this.loadMoreHistory.bind(this);
        this.clearItemHistory = this.clearItemHistory.bind(this);
        this.clearHistory = this.clearHistory.bind(this);
    }

    clearItemHistory = (id) => {
        const {
            network: {isLoggedIn},
            setReduxStateFromCookie,
            getBrowsingHistory,
            getBrowsingHistoryFromCookieProductsData,
        } = this.props;
        const requestUri = encodeURI(`/CatalogService/api/v1/recent/${id}`);

        if (isLoggedIn) {
            axios
                .delete(requestUri)
                .then(response => {
                    let {viewItems} = this.state;
                    _.remove(viewItems, {product: {id: id}});

                    this.setState({
                        viewItems: viewItems,
                        history: _.groupBy(viewItems, e => e.dateViewed.slice(0, 10)),
                        viewIsEmpty: viewItems.length === 0,
                        start: 0,
                    });
                })
                .then(getBrowsingHistory);
        } else {
            let browsingCookie = [];
            let browsingHistoryStr = getCookie('browsingHistory');

            if (browsingHistoryStr !== '') {
                try {
                    browsingCookie = JSON.parse(browsingHistoryStr);
                    _.remove(browsingCookie, b => b.productOString === id);
                } catch (err) {
                    // do nothing
                }
            }

            let {viewItems} = this.state;
            _.remove(viewItems, {product: {id: id}});

            this.setState({
                viewItems: viewItems,
                history: _.groupBy(viewItems, x => x.dateViewed.slice(0, 10)),
                viewIsEmpty: viewItems.length === 0,
                start: 0,
            });

            setReduxStateFromCookie(browsingCookie);
            getBrowsingHistoryFromCookieProductsData();
        }
    }

    clearHistory = () => {
        const {emptyItemHistory, getBrowsingHistory} = this.props;

        axios
            .delete('/CatalogService/api/v1/recent')
            .then(response => {
                this.setState({
                    viewIsEmpty: true,
                    viewItems: [],
                    history: {},
                    start: 0,
                });
            })
            .then(getBrowsingHistory);

        deleteCookie('browsingHistory');
        emptyItemHistory();
    }

    componentDidMount() {
        this.loadMoreHistory();
    }

    loadMoreHistory = () => {
        const {start, limit, moreAvailable, isBusy} = this.state;
        const {
            network: {isLoggedIn},
            cart: {itemHistory},
        } = this.props;

        if (isLoggedIn) {
            if (moreAvailable && !isBusy) {
                const request = {
                    start: start,
                    limit: limit,
                };

                this.setState({isBusy: true});
                axios
                    .get('/CatalogService/api/v1/recent', request)
                    .then(response => {
                        const {
                            data: {views},
                        } = response;
                        let {viewItems} = this.state;

                        if (start === 0) {
                            viewItems = views;
                        } else {
                            viewItems = _.union(viewItems, views);
                        }

                        let newStart = start + limit;
                        const moreAvailable = views.length === limit;

                        this.setState({
                            viewItems: viewItems,
                            history: _.groupBy(viewItems, e => e.dateViewed.slice(0, 10)),
                            moreAvailable: moreAvailable,
                            isBusy: false,
                            viewIsEmpty: start === 0 && views.length === 0,
                            start: newStart,
                        });
                    })
                    .catch(() => this.setState({isBusy: false}));
            }
        } else if (
            itemHistory &&
            itemHistory.views &&
            itemHistory.views.length > 0
        ) {
            this.setState({
                viewItems: itemHistory.views,
                history: _.groupBy(itemHistory.views, e => e.dateViewed.slice(0, 10)),
                moreAvailable: false,
                isBusy: false,
                viewIsEmpty: false,
            });
        } else {
            this.setState({history: [], viewIsEmpty: true});
        }
    }

    LogWebEvent = (product) => logEvent('BROWSING HISTORY CLICK', {'O-String': product.id});

    renderItem = (item) => {
        const {product, orderDetailUrl, datePurchased} = item;
        let id;
        // productId ? id = productId : id = product.id;
        id = product.id;
        return (
            <div key={`pdiv_${id}`} className="col-lg-3 col-md-4 col-sm-6 col-xs-12" style={{marginBottom: 20}}>
                <div className="carousel-item-wrapper">
                    <a style={{cursor: 'pointer', fontWeight: 'bold', float: 'right'}} onClick={() => this.clearItemHistory(id)}> X </a>
                    <div>
                        <NavLink to={product.detailUrl} title={product.title} onClick={() => this.LogWebEvent(product)}>
                            <div className="carousel-image" >
                                <ProductImage url={product.thumbnailUrl} style={{width: '100%'}} />
                            </div>
                        </NavLink>
                        <div className="content-wrapper">
                            <div className="product-description">
                                <NavLink to={product.detailUrl} title={product.title} onClick={() => this.LogWebEvent(product)}>
                                    <TextTruncate
                                        line={3}
                                        truncateText="…"
                                        text={product.title} />
                                </NavLink>
                            </div>
                            <div className="product-category lbl-subtle">{product.brand}</div>
                            <span className="orderDetailUrl">
                                {orderDetailUrl && <NavLink to={orderDetailUrl}>{`Purchased ${moment(datePurchased).calendar(null, momentCalendarSettings)}`}</NavLink>}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderCarousel = (dateKey, items) => {
        return (
            <div key={dateKey} className={`col-md-12 product-carousel`} style={{border: 0}}>
                <div className="slick-title">
                    {moment(dateKey).calendar(null, momentCalendarSettings)}
                </div>
                <Slider {...carouselSettings}>{<div className="row"> {items.map(this.renderItem)}</div>}</Slider>
            </div>
        );
    }

    render() {
        const {history, viewIsEmpty, isBusy} = this.state;

        if (!_.isEmpty(history)) {
            _.forEach(history, function (historyItem, key) {
                historyItem = historyItem.sort(function (x, y) {
                    return new Date(y.dateViewed) - new Date(x.dateViewed);
                });
            });
        }

        return (<React.Fragment>
            <PageMetaData title="Browsing History" />
            <div className="browserHistory">
                <h1 className="title">Browsing History</h1>
                <button className="clearAll" onClick={this.clearHistory}>
                    CLEAR ALL BROWSING HISTORY
                </button>
                {viewIsEmpty ? (
                    <h3>You have not viewed any products</h3>
                ) : (
                    <Infinite
                        elementHeight={500}
                        useWindowAsScrollContainer={true}
                        infiniteLoadBeginEdgeOffset={20}
                        onInfiniteLoad={this.loadMoreHistory}
                        isInfiniteLoading={isBusy}
                    >
                        {Object.keys(history).map(key =>
                            this.renderCarousel(key, history[key])
                        )}
                    </Infinite>
                )}
            </div>
        </React.Fragment>);
    }
}
